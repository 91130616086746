<template>
  <div>
      <h2 class="text-center fw-bolder mt-4 title">Podrobnejši vremenski podatki</h2>
      <h6 class="text-center note">(Za še več podatkov kliknite na postajo)</h6>
    <StationList />
  </div>
</template>

<script>
import StationList from "../components/Stations/StationList.vue";

export default {
  name: "Stations",
  components: {
    StationList,
  },
};
</script>

<style scoped>
.title {
    font-size: 37px;
}

.note {
    font-size: 18px;
}
</style>