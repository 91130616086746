<template>
  <div class="container mt-4">
    <div class="row g-2 gap-5 justify-content-center">
      <div
        class="col-12 col-md-3"
        v-for="station in GetTableData.firstRow"
        :key="station.id"
      >
        <Station :station="station" />
      </div>
    </div>
    <div class="row g-2 gap-5 justify-content-center mt-5">
      <div
        class="col-12 col-md-3"
        v-for="station in GetTableData.secondRow"
        :key="station.id"
      >
        <Station :station="station" />
      </div>
    </div>
  </div>
</template>

<script>
import Station from "./Station.vue";

export default {
  name: "StationList",
  data() {
    return {
      stationList: null,
    };
  },
  computed: {
    GetTableData() {
      return this.$store.getters.stationsData;
    },
  },
  created() {
    this.$store.dispatch("getWidgetData");
  },
  watch: {
    $route() {
      this.$store.dispatch("getWidgetData");
    },
  },
  components: {
    Station,
  },
};
</script>